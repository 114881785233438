document.addEventListener("turbolinks:load", () => {
  const uploadDocumentsModal = $("#upload-documents-modal")
  const fileInput = $("#input-upload-documents")

  if (!uploadDocumentsModal) return

  $(uploadDocumentsModal).on("hidden.bs.modal", function () {
    $(fileInput).fileinput("clear")
  })

  $(fileInput).on("fileuploaded", function (event, data, index, fileId) {
    $("ul#document-list").replaceWith(data.response.html)
  })

  $(fileInput).on("filebatchuploadsuccess", function (event, data) {
    $("ul#document-list").replaceWith(data.response.html)

    setTimeout(() => {
      uploadDocumentsModal.modal("hide")
    }, 500)
  })
})
