import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectedInstrument", "addInstrument", "selectedAlert", "addAlert", "selectedAnnotation", "addAnnotation"]

  connect() {}

  selectedInstrumentTargetConnected() {
    this.enableAddInstrument()
  }

  selectedAlertTargetConnected() {
    this.enableAddAlert()
  }

  selectedAnnotationTargetConnected() {
    this.enableAddAnnotation()
  }

  enableAddInstrument() {
    this.addInstrumentTarget.disabled = this.selectedInstrumentTarget.value === ""
  }

  enableAddAlert() {
    this.addAlertTarget.disabled = this.selectedAlertTarget.value === ""
  }

  enableAddAnnotation() {
    this.addAnnotationTarget.disabled = this.selectedAnnotationTarget.value === ""
  }
}
