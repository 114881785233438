import flatpickr from "flatpickr"
import moment from "moment/moment"

document.addEventListener("turbolinks:load", () => {
  flatpickr(".flatpickr-date-time", {
    dateFormat: "Y-m-d H:i",
    enableTime: true,
    allowInput: true,
  })

  flatpickr(".flatpickr-date", {
    dateFormat: "Y-m-d",
    allowInput: true,
  })

  flatpickr(".flatpickr-time", {
    noCalendar: true,
    enableTime: true,
    dateFormat: "h:i K",
  })

  flatpickr(".flatpickr-time-window", {
    altInput: true,
    altFormat: "h:i K",
    dateFormat: "H:i",
    enableTime: true,
    noCalendar: true,
    allowInput: true,
  })

  const yesterday = moment().subtract(1, 'day')
  const twoDaysAgo = moment().subtract(2, 'day')
  const maxDate = moment().isAfter(moment('1:30am', 'h:mma')) ? yesterday : twoDaysAgo
  flatpickr(".flatpickr-statistics-range", {
    mode: "range",
    maxDate: maxDate.toDate(),
    allowInput: true,
  })
})
