import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "showLegend",
    "device",
    "deviceTypeField",
    "chartPanel",
  ]

  static values = {
    url: String
  }

  connect() {
    this.filter()
  }

  showLegend() {
    this.chart.options.plugins.legend.display = !this.chart.options.plugins.legend.display
    this.chart.update()
  }

  filter() {
    const url = this.urlValue
    const data = {
      device_id: this.deviceId,
      device_type_field: this.deviceTypeField,
    }

    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams(data).toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)

        return true
      },
      success: data => {
        if (data.readings) {
          this.renderDisplacement(data)
        }
      },
      error: data => {
        alert(data)
      },
      complete: () => {
        spinnerEl.remove()
      }
    })
  }

  selectColor(number) {
    const hue = number * 137.508; // use golden angle approximation
    return `hsl(${hue},100%,75%)`;
  }

  renderDisplacement(data) {
    const default_color = "#ff6961"
    const ctx = $("#vertical-displacement-chart")

    const elevations = []
    const datasets = []
    const labels = []
    const chart_annotations = []
    const dataset = {}

    const { 
      readings, 
      annotations, 
      device_type_field, 
      field_unit, 
      y_axis_title, 
      x_axis_title 
    } = data

    if(readings.length > 0){
      if ('data' in readings[0]){
          const first_datum = readings[0].data.array.sort((a, b) => (b.elevation - a.elevation))
          first_datum.forEach(datum => {
            elevations.push(datum.elevation)
            dataset[datum.elevation] = {}
            dataset[datum.elevation]['data'] = []
          })
      }
    }

    readings.forEach((reading, idx) => {
        const timestamp = moment(reading.time_in_zone, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
        labels.push(timestamp)

        elevations.forEach((elevation, e_idx) => {
            const color = this.selectColor(e_idx)
            dataset[elevation]['label'] = elevation
            dataset[elevation]['fill'] = false
            dataset[elevation]['backgroundColor'] = color
            dataset[elevation]['borderColor'] = color

            reading.data.array.forEach((datum) => {
                if(datum["elevation"] == elevation) {
                    dataset[elevation]['data'].push(datum[this.deviceTypeField])
                }
            })
        })
    })

    elevations.forEach((elevation) => {
        datasets.push(dataset[elevation])
    })

    let label = device_type_field
    if (field_unit != "") {
      label = label.concat(" \(", field_unit, "\)")
    }
   
    annotations.map((annotation, index) => {
        chart_annotations.push(
          {
            type: 'line',
            id: 'alert'+ index + '_min',
            value: annotation.alert_min,
            endValue: annotation.alert_min,
            borderColor: annotation.color || default_color,
            borderWidth: 1,
            display: (annotation.alert_min) ? true : false,
            label: {
              enabled: true,
              position: index == 0 ? "start" : "end",
              content: `${annotation.title || ""} ${annotation.alert_min}`,
              backgroundColor: 'rgba(0,0,0,0.3)'
            },
            scaleID: index == 0 ? "y" : "y1"
          },
          {
            type: 'line',
            id: 'alert'+ index + '_max',
            value: annotation.alert_max,
            endValue: annotation.alert_max,
            borderColor: annotation.color || default_color,
            borderWidth: 1,
            display: (annotation.alert_max) ? true : false,
            label: {
              enabled: true,
              position: index == 0 ? "start" : "end",
              content: `${annotation.title || ""} ${annotation.alert_max}`,
              backgroundColor: 'rgba(0,0,0,0.3)'
            },
            scaleID: index == 0 ? "y" : "y1"
        })
    });

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          annotation: {
            drawTime: "afterDraw",
            annotations: chart_annotations
          }
        },
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: y_axis_title
            }
          },
          x: {
            title: {
              display: true,
              text: x_axis_title
            },
            ticks: {
                stepSize: 10
            }
          },
        },
      },
    })

    // reinitialize on chart creation
    this.showLegendTarget.checked = true
    this.showLegendTarget.disabled = false
  }

  redrawCanvas() {
    $("#vertical-displacement-chart")?.remove()
    const canvas = document.createElement("canvas")
    canvas.id = "vertical-displacement-chart"

    $(".vertical-displacement-chart-area").append(canvas)
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTarget.value
  }
}
