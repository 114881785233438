import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["canvas"]
  static values = { reading: Number, field: String }

  connect() {
    this.render()
  }

  get reading() {
    this.readingValue
  }

  render() {
    let ctx = this.canvasTarget.getContext("2d")
    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
    let gradientStroke2 = ctx.createLinearGradient(500, 0, 100, 0)

    gradientStroke.addColorStop(0, "#e74a3b")
    gradientStroke.addColorStop(1, "#fd7e14")
    gradientStroke2.addColorStop(0, "#e3e3e3")
    gradientStroke2.addColorStop(1, "#ededed")

    let config = {
      type: "pie",
      data: {
        labels: [this.fieldValue],
        datasets: [
          {
            data: [this.readingValue, 100 - this.readingValue],
            backgroundColor: [gradientStroke, gradientStroke2],
            borderWidth: 0,
            label: "Reading",
          },
        ],
      },
      options: {
        animation: { duration: 0 },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        centerPercentage: 80,
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        centerArea: {
          text: this.readingValue,
        },
      },
    }

    new Chart(ctx, config)
  }
}
