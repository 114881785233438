import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = { lon: Number, lat: Number, id: String }

  connect() {
    this.render()
  }

  get reading() {
    this.readingValue
  }

  render() {
    const mymap = L.map(this.idValue).setView([this.latValue, this.lonValue], 16)
    mymap.zoomControl.remove()

    const layer = new L.TileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
    mymap.addLayer(layer)

    const marker = L.marker([this.latValue, this.lonValue]).addTo(mymap);
    mymap.addLayer(marker)
  }
}
