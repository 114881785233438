document.addEventListener("turbolinks:load", () => {
  const accordionSidebar = document.getElementById("accordionSidebar")
  const sidebarToggle = document.getElementById("sidebarToggle")
  const key = 'sidebarState'

  if (accordionSidebar == null) {
    return
  }

  let collapsed = JSON.parse(localStorage.getItem(key))
  if (collapsed == null) {
    collapsed = false
  }

  if (collapsed) {
    document.body.classList.add("sidebar-toggled")
    document.getElementById("accordionSidebar")?.classList.add("toggled")
  } else {
    document.body.classList.remove("sidebar-toggled")
    accordionSidebar.classList.remove("toggled")
  }

  sidebarToggle.addEventListener("click", ()=> {
    let collapsed = accordionSidebar.classList.contains("toggled")
    localStorage.setItem(key, !collapsed)
  })

})
