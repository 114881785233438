document.addEventListener("turbolinks:load", () => {
  let fileInput = document.querySelector(".image-uploader")
  if (typeof fileInput == "undefined" || fileInput == null) {
    return
  }

  let imageBrowser = document.getElementById("image-browser")
  if (imageBrowser) {
    imageBrowser.addEventListener("click", () => {
      fileInput.click()
    })
  }

  fileInput.addEventListener("change", function () {
    if (this.files && this.files[0]) {
      var imageViewer = document.getElementById("image-viewer")
      imageViewer.onload = () => {
        URL.revokeObjectURL(imageViewer.src) // no longer needed, free up memory
      }

      imageViewer.src = URL.createObjectURL(this.files[0]) // set src to blob url

      var fileName = $(this).val().split("\\").pop()
      $(this).siblings(".custom-file-label").addClass("selected").html(fileName)
    }
  })
})
