import "select2"
import "select2/dist/css/select2.css"
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css"

$(document).on("turbolinks:load", () => {
  $(".select2").select2({ width: "100%"})

  $(document).on("select2:open", () => {
    document.querySelector(".select2-search__field").focus()
  })

  $(".select2-selection__choice").css("background-color", "transparent")
  $(document).on("change.select2", () => {
    $(".select2-selection__choice").css("background-color", "transparent")
  })
})
