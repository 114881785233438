import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["canvas"]
  static values = { latestReadings: Array, fieldUnit: String }

  connect() {
    this.renderChart()
  }

  renderChart() {
    const ctx = this.canvasTarget.getContext("2d")
    const latestReadings = this.latestReadingsValue
    const timestamps = latestReadings.map((entry) =>
      new Date(entry[1]).toLocaleTimeString("en-US", {
        month: "short",
        day: "numeric",
      })
    )

    const values = latestReadings.map((entry) => entry[0])

    new Chart(ctx, {
      type: "line",
      data: {
        labels: timestamps,
        datasets: [
          {
            data: values,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          x: { ticks: { display: false } },
          y: {
            beginAtZero: false,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (item) => `${item.formattedValue} ${this.fieldUnitValue}`,
            },
          },
        },
      },
    })
  }
}
