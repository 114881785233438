function selectColor(number) {
  const hue = number * 137.508 // use golden angle approximation
  return `hsl(${hue},100%,75%)`
}

function selectColorDisplacement(index, total) {
  // assigns color for the first 1 and latest 3 readings only
  // colors: red, orange, yellow
  const colors = ['#f03b20', '#fd8d3c', '#fecc5c']
  const tailIndex = total - (index + 1)
  
  if (index === 0) {
    return '#31a354' // green for first reading
  } else if (tailIndex < 3 ) {
    return colors[tailIndex]
  }

  return '#d3d3d3' // default: light gray
}

function hexToRGB(hex, alpha) {
  hex = hex.toUpperCase()

  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

function toConfig(labelContent, value, color, scaleID) {
  const default_color = '#ff6961'
  if (scaleID === 'y1') labelContent.push('(Dataset 2)')
  // annotation config for chartjs plugin annotation
  return {
    type: 'line',
    id: null,
    value: value,
    endValue: value,
    borderColor: hexToRGB(color || default_color, 0.2),
    borderWidth: 3,
    display: true,
    label: {
      enabled: false,
      backgroundColor: 'rgba(0,0,0,0.5)',
      content: labelContent
    },
    scaleID: scaleID,
    isPinned: false
  }
}

function getAnnotations ({ annotations, index = 0 }) {
  const yAxes = ['y', 'y1']
  const annotationTimestamps = []
  const datasetAnnotations = []
  const displacementLabels = []

  annotations.forEach(annotation => {
    const { title, min, max, timestamp, color, type } = annotation
    const scaleID = (type === 'vertical') ? 'x' : yAxes[index]
    const labelTitle = title || ''

    if (timestamp) {
      const timestampNew = moment(timestamp, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
      const labelContent = [`${labelTitle}`, `${timestampNew}`]
      datasetAnnotations.push(toConfig(labelContent, timestampNew, color, scaleID))
      annotationTimestamps.push(timestampNew)   // will be added to the chart timestamp labels
    } else {
      if (min != null) {
        const labelContent = [`${labelTitle} ${min}`]
        datasetAnnotations.push(toConfig(labelContent, `${min}`, color, scaleID))
        if (scaleID === 'y') displacementLabels.push(`${min}`)
      }

      if (max != null) {
        const labelContent = [`${labelTitle} ${max}`]
        datasetAnnotations.push(toConfig(labelContent, `${max}`, color, scaleID))
        if (scaleID === 'y') displacementLabels.push(`${max}`)
      }
    }
  })

  return { datasetAnnotations, annotationTimestamps, displacementLabels }
}

function toggleAnnotation(element, event, enabled, cursor) {
  const chart = event.chart
  const annotationOpts = chart.options.plugins.annotation.annotations[element.options.id]
  event.native.target.style.cursor = cursor
  if (!annotationOpts.isPinned) {
    const opacity = enabled ? ['0.2', '0.8'] : ['0.8', '0.2']   // [from, to]
    const scaleID = annotationOpts.scaleID
    annotationOpts.borderColor = annotationOpts.borderColor.replace(opacity[0], opacity[1])
    annotationOpts.label.enabled = enabled
    if (scaleID === 'x') {
      // vertical annotations
      annotationOpts.label.position = ((event.y - chart.chartArea.top) / chart.chartArea.height * 100) + '%'
    } else {
      annotationOpts.label.position = ((event.x - chart.chartArea.left) / chart.chartArea.width * 100) + '%'
    }
    chart.update()
  }
}

const annotationMouseEvents = {
  enter({element}, event) {
    toggleAnnotation(element, event, true, 'pointer')
  },
  leave({element}, event) {
    toggleAnnotation(element, event, false, 'default')
  },
  click({element}, event) {
    const chart = event.chart
    const annotationOpts = chart.options.plugins.annotation.annotations[element.options.id]
    annotationOpts.isPinned = !annotationOpts.isPinned
    annotationOpts.borderColor = annotationOpts.borderColor.replace('0.2', '0.8')
    chart.update()
  }
}

// merge duplicate annotations
function mergeAnnotations(allAnnotations) {
  const mergedAnnotations = [...new Set(allAnnotations.map(JSON.stringify))].map(JSON.parse)
  mergedAnnotations.forEach((annotation, index) => annotation.id = index)
  
  return mergedAnnotations
}

// merge duplicate labels
function mergeLabels(labels, forDisplacement) {
  const mergedLabels = [...new Set(labels)]
  if (forDisplacement) {
    mergedLabels.sort((a,b) => a - b).reverse()
  } else {
    mergedLabels.sort((a,b) => moment(a, 'DD-MMM-YYYY hh:mm A').diff(moment(b, 'DD-MMM-YYYY hh:mm A')))
  }
  
  return mergedLabels
}

function progressBarHTML() {
  const progressBar = document.createElement("div")
  progressBar.id = "progress-div"
  progressBar.innerHTML = `
    <div class="text-gray text-center">Fetching data...</div>
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
    </div>
  `
  
  return progressBar
}

function updateProgressBar(value) {
  let div = $('div.progress-bar')
  div.attr('aria-valuenow', value);
  div.css('width', value + '%');
  div.text(value + '% Complete');
}

module.exports = { 
  selectColor,
  selectColorDisplacement,
  getAnnotations,
  annotationMouseEvents,
  mergeAnnotations,
  mergeLabels,
  progressBarHTML,
  updateProgressBar
}
