import flatpickr from "flatpickr"

document.addEventListener("turbolinks:load", () => {
  flatpickr('.flatpickr.js-flatpickr-inline', {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: new Date().fp_incr(1),
    inline: true,
    static: true
  })
})
