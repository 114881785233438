$(document).on("turbolinks:load", function () {
    $(".confirmation").keyup(function(e) {
      if ($(this).val().toUpperCase() === "YES") {
        $(".delete-btn").prop("disabled", false);
      } else {
        $(".delete-btn").prop("disabled", true);
      }
    });
  
    $("[id*='deleteReadingModal']").on("hidden.bs.modal", function (e) {
      $(".confirmation").val("");
      $(".delete-btn").prop("disabled", true);
    });
  });