import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import 'chartjs-adapter-moment';
import { 
  selectColor,
  getAnnotations,
  annotationMouseEvents,
  mergeAnnotations,
  mergeLabels,
  progressBarHTML,
  updateProgressBar
} from "./helpers/charts"

const datasetData = []

export default class extends Controller {
  static targets = [
    "showLegend",
    "showAnnotations",
    "multiAxis",
    "site",
    "deviceGroup",
    "deviceType",
    "device",
    "deviceSelect",
    "deviceTypeField",
    "startDateTime",
    "endDateTime",
    "timeZone",
    "submit",
    "chartPanel",
    "timeWindow",
    "startDate",
    "endDate",
    "startTimeWindow",
    "endTimeWindow",
  ]

  static values = {
    datasetId: { type: Number, default: 1 },
    clickedDataset: {type: Number, default: 1},
    selectedDevices: Array
  }

  connect() {
    this.selectedDevicesValue = $(this.deviceSelectTarget).val()
    $(this.deviceSelectTarget).on("select2:select", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    $(this.deviceSelectTarget).on("select2:unselect", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    // if turbolinks is showing preview, don't create chart yet
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.allowSubmit()
      this.filter()
    }
  }

  showLegend() {
    this.chart.options.plugins.legend.display = !this.chart.options.plugins.legend.display
    this.chart.update()
  }

  showAnnotations() {
    const annotations = this.chart.options.plugins.annotation.annotations
    annotations.forEach(annotation => annotation.display = !annotation.display)
    this.chart.update()
  }

  setDatasetId(e) {
    this.datasetIdValue = e.target.dataset.datasetid
    this.clickedDatasetValue = e.target.dataset.datasetid
  }
  
  multiAxisChanged() {
    if (!this.isMultiAxis) this.datasetIdValue = 1
    this.allowSubmit()
  }

  getAllDevices() {
    const allDevices = [...$(this.deviceSelectTarget).children()].map(o => o.value)
    allDevices.shift()
    return allDevices
  }

  clearSelection() {
    $(this.deviceSelectTarget).val(null).trigger('change');
    this.selectedDevicesValue = []
    this.allowSubmit()
  }

  clearInputs(index) {
    if (index == 0) {
      $(this.deviceSelectTarget).empty().trigger("change")
    } else {
      this.deviceTarget.innerHTML = "<option value></option>"
    }
    this.deviceTypeFieldTargets[index].innerHTML = "<option value></option>"
  }

  siteChanged() {
    const url = this.siteTargets[this.datasetIndex].dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId, dataset_id: this.datasetIdValue }).toString(),
      contentType: "application/json",
      success: data => {
        // active tab: dataset 1
          const { device_groups, device_types } = data
          this.deviceGroupTargets[this.datasetIndex].innerHTML = "<option value></option>"
          device_groups.forEach(deviceGroup => {
            const option = document.createElement("option")
            option.value = deviceGroup.id
            option.text = deviceGroup.name
            this.deviceGroupTargets[this.datasetIndex].appendChild(option)
          })

          this.deviceTypeTargets[this.datasetIndex].innerHTML = "<option value></option>"
          device_types.forEach((deviceType) => {
            const option = document.createElement("option")
            option.value = deviceType.id
            option.text = deviceType.name
            this.deviceTypeTargets[this.datasetIndex].appendChild(option)
          })
        

        this.clearInputs(this.datasetIndex)
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceGroupChanged() {
    const url = this.deviceGroupTargets[this.datasetIndex].dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ device_group_id: this.deviceGroupId }).toString(),
      contentType: "application/json",
      success: data => {
        this.deviceTypeTargets[this.datasetIndex].innerHTML = "<option value></option>"
        data.forEach(deviceType => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTargets[this.datasetIndex].appendChild(option)
        })

        this.clearInputs(this.datasetIndex)
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceTypeChanged() {
    const url = this.deviceTypeTargets[this.datasetIndex].dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({
        site_id: this.siteId,
        device_group_id: this.deviceGroupId,
        device_type_id: this.deviceTypeId,
        dataset_id: this.datasetIdValue
      }).toString(),
      contentType: "application/json",
      success: data => {
        const { devices, device_type_fields } = data
        // active tab: dataset 1
        if (this.datasetIdValue === 1) {
          $(this.deviceSelectTarget).empty().trigger('change');
          this.deviceSelectTarget.innerHTML = "<option value='0'>--Select All--</option>"
          devices.forEach(device => {
            const option = document.createElement("option")
            option.value = device.id
            option.text = device.name
            this.deviceSelectTarget.appendChild(option)
          })
  
        } else {
          this.deviceTarget.innerHTML = "<option value></option>"
          devices.forEach(device => {
            const option = document.createElement("option")
            option.value = device.id
            option.text = device.name
            this.deviceTarget.appendChild(option)
          })
        }

        this.deviceTypeFieldTargets[this.datasetIndex].innerHTML = "<option value></option>"
        device_type_fields.forEach(field => {
          const option = document.createElement("option")
          option.value = field
          option.text = field
          this.deviceTypeFieldTargets[this.datasetIndex].appendChild(option)
        })
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceChanged(e) {
    if (e.detail) {
      let selected = e.detail.devices

      // select all devices
      if(selected[0] === '0') {
        selected = this.getAllDevices()
        $(this.deviceSelectTarget).val(selected).trigger('change');
      }

      this.selectedDevicesValue = selected
    }
    this.allowSubmit()
  }

  allowSubmit() {
    this.submitTarget.disabled = !this.isValid()
  }

  submit() {
    this.datasetIdValue = 1
    this.filter()
  }

  removeLoadingEl(el) {
    this.submitTarget.textContent = "Submit"
    this.submitTarget.disabled = false
    el.remove()
  }
  
  filterData(page, searchParams, index, spinnerEl) {
    searchParams.set('page', page)
    return Rails.ajax({
      type: "get",
      url: this.submitTarget.dataset.url,
      dataType: "json",
      data: searchParams.toString(),
      contentType: "application/json",
      success: data => {
        if (data.readings) {
          if (page == 1) {
            datasetData.push(data)
          } else {
            datasetData[index]['readings'] = datasetData[index]['readings'].map((deviceReading, idx) => {
              return deviceReading.concat(data.readings[idx])
            })
          }

          // if Multi-axis:
          // dataset 1 = progress * 50%
          // dataset 2 = (progress * 50%) + 50%
          const percent = this.isMultiAxis ? 50 : 100
          const progressValue = Math.floor((page/data.total_pages + index) * percent)
          updateProgressBar(progressValue)

          if (page < data.total_pages) {
            this.filterData(page + 1, searchParams, index, spinnerEl)
          } else {
            if (this.isMultiAxis && index < 1) {
              this.filterDatasetTwo(spinnerEl)
            } else {
              if (datasetData.length) {
                this.renderHistogram(datasetData)
                this.datasetIdValue = this.clickedDatasetValue // set to current tab on page
                datasetData.length = 0
              }

              this.removeLoadingEl(spinnerEl)
            }
          }
        }
      },
      error: data => {
        alert(data)
      }
    })
  }

  filter() {
    const progressBarEl = progressBarHTML()

    let url = this.submitTarget.dataset.url
    let data = {
      site_id: this.siteId,
      device_group_id: this.deviceGroupId,
      device_type_id: this.deviceTypeId,
      device_type_field: this.deviceTypeField,
      start_date_time: this.startDateTime,
      end_date_time: this.endDateTime,
      time_zone: this.timeZone,
      is_time_window: this.timeWindowTarget.checked
    }

    // add device ids to search parameters
    let searchParams = new URLSearchParams(data)
    this.selectedDevicesValue.forEach(id => searchParams.append('device_id[]', id))

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: searchParams.toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(progressBarEl)
        this.submitTarget.disabled = true
        this.submitTarget.textContent = "Please wait..."

        return true
      },
      success: data => {
        if (data.readings) {
          this.filterData(1, searchParams, 0, progressBarEl)
        } else {
          this.removeLoadingEl(progressBarEl)
        }
      },
      error: data => {
        alert(data)
      }
    })

  }

  filterDatasetTwo(progressBarEl) {
    this.datasetIdValue = 2
    const url = this.submitTarget.dataset.multiaxisUrl
    const data = {
      site_id: this.siteId,
      device_id: this.deviceId, 
      device_type_id: this.deviceTypeId,
      device_type_field: this.deviceTypeField,
      start_date_time: this.startDateTime,
      end_date_time: this.endDateTime,
      time_zone: this.timeZone,
      is_time_window: this.timeWindowTarget.checked
    }
    let searchParams = new URLSearchParams(data)
    
    // get dataset 2 data
    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: searchParams.toString(),
      contentType: "application/json",
      success: data => {
        if (data.readings) {
          this.filterData(1, searchParams, 1, progressBarEl)
        } else {
          this.removeLoadingEl(progressBarEl)
        }
      },
      error: data => {
        alert(data)
      }
    })
  }

  renderHistogram(datasetData) {
    const ctx = $("#histogram-chart")

    const allAnnotations = []
    const datasets = []
    const labels = []
    const y_title = []
    const y_reverse = []
    let x_title

    datasetData.forEach((data, index) => {
      const { 
        devices,
        readings, 
        alert_annotations, 
        annotations, 
        device_type_field, 
        field_unit, 
        y_axis_title, 
        x_axis_title,
        y_axis_reverse = false,
        precision
      } = data
      x_title = x_axis_title
      y_title.push(y_axis_title)
      y_reverse.push(y_axis_reverse)

      readings.forEach((reading, idx) => {
        if (reading.length) {
          const values = []
          const color = selectColor(idx)

          let label = devices[idx][0]   // get device name
          if (field_unit && field_unit.length) label = `${label} (${field_unit})`
  
          reading.forEach(({ time_in_zone, data }) => {
            if(!(device_type_field in data)){
                return
            }
            if(isNaN(data[device_type_field])){
                return
            }

            const timestamp = moment(time_in_zone, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
            labels.push(timestamp)
            values.push(
              {
                x: Date.parse(timestamp), // unix epoch is required for x-axis (since parsing must be false)
                y: parseFloat(data[device_type_field].toFixed(precision))
              }
            )
          })

          // if Dataset 2
          if (this.isMultiAxis && index === 1) {
            datasets.push(
              {
                label: `[Dataset 2] ${label}`,
                data: values,
                backgroundColor: "#f0ad4e",
                yAxisID: "y1"
              }
            )
          } else {
            datasets.push(
              {
                label: label,
                data: values,
                backgroundColor: color,
                borderColor: color,
                yAxisID: "y"
              }
            )
          }
        }
      })

      // get alert_annotations config for chart 
      if (alert_annotations && alert_annotations.length) {
        const { datasetAnnotations } = getAnnotations({ annotations: alert_annotations, index })
        allAnnotations.push(...datasetAnnotations)
      }

      // get annotations config for chart
      if (annotations && annotations.length) {
        const { datasetAnnotations, annotationTimestamps } = getAnnotations({ annotations, index })
        if (annotationTimestamps.length) labels.push(...annotationTimestamps)
        allAnnotations.push(...datasetAnnotations)
      }
    })

    // merge same labels and annotations
    const mergedLabels = mergeLabels(labels)
    const mergedAnnotations = mergeAnnotations(allAnnotations)

    // chart title
    const selected = this.deviceTypeTargets[0].selectedIndex
    const deviceType = this.deviceTypeTargets[0].options[selected].text
    const deviceTypeField = this.deviceTypeFieldTargets[0].value
    const chartTitle = `${deviceType} - ${deviceTypeField}`

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: mergedLabels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        spanGaps: true,
        plugins: {
          annotation: {
            annotations: mergedAnnotations,
            ...annotationMouseEvents
          },
          title: {
            display: true,
            text: chartTitle,
            font: {
              size: 16
            }
          },
          tooltip: {
            callbacks: {
              label: (ctx) => (`${ctx.dataset.label}: ${ctx.raw.y}`)
            }
          },
          decimation: { 
            // used for data sampling
            enabled: true,
            algorithm: 'min-max',
            samples: 250,
            threshold: 500
          }
        },
        parsing: false, // required for decimation
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: x_title
            },
            type: 'time',
            time: {
              displayFormats: {
                minute: 'DD-MMM-YYYY hh:mm A',
                hour: 'DD-MMM-YYYY hh:mm A',
                day: 'DD-MMM-YYYY',
                month: 'DD-MMM-YYYY',
                year: 'DD-MMM-YYYY'
              },
            },
            ticks: {
              autoSkip: true,
              maxRotation: 45
            }
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            title: {
              display: true,
              text: y_title[0]
            },
            reverse: y_reverse[0]
          },
          y1: {
            type: "linear",
            display: this.isMultiAxis,
            position: "right",
            title: {
              display: true,
              text: this.isMultiAxis ? y_title[1] : ""
            },
            grid: {
              drawOnChartArea: false,
            },
            reverse: this.isMultiAxis ? y_reverse[1] : y_reverse[0]
          },
        },
      },
    })

    // reinitialize on chart creation
    this.showLegendTarget.checked = true
    this.showLegendTarget.disabled = false
    this.showAnnotationsTarget.checked = mergedAnnotations.length
    this.showAnnotationsTarget.disabled = !mergedAnnotations.length
  }

  isValid() {
    if (this.isMultiAxis) {
      const isDeviceTypeComplete = this.deviceTypeTargets.every(o => o.value != "")
      const isDeviceTypeFieldComplete = this.deviceTypeFieldTargets.every(o => o.value != "")

      if (!isDeviceTypeComplete
          || this.deviceId.length == 0
          || this.selectedDevicesValue.length == 0
          || !isDeviceTypeFieldComplete
          || this.startDateTime.length == 0
          || this.endDateTime.length == 0) {
        return false
      }
    } else {
      if (this.deviceTypeId.length == 0
          || this.selectedDevicesValue.length == 0
          || this.deviceTypeField.length == 0
          || this.startDateTime.length == 0
          || this.endDateTime.length == 0) {
        return false
      }
    }

    return true
  }

  redrawCanvas() {
    $(".chart-container").empty()
    const div = document.createElement("div")
    div.classList.add("col", "p-4")
    const canvas = document.createElement("canvas")
    canvas.id = "histogram-chart"
    $(".chart-container").append(canvas)
    $("canvas").wrap(div)
  }

  formatTimeWindow(date, time) {
    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get siteId() {
    return this.siteTargets[this.datasetIndex].value
  }

  get deviceGroupId() {
    return this.deviceGroupTargets[this.datasetIndex].value
  }

  get deviceTypeId() {
    return this.deviceTypeTargets[this.datasetIndex].value
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTargets[this.datasetIndex].value
  }

  get startDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.startDateTarget.value, this.startTimeWindowTarget.value)
    }
    return this.startDateTimeTarget.value
  }

  get endDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.endDateTarget.value, this.endTimeWindowTarget.value)
    }
    return this.endDateTimeTarget.value
  }

  get timeZone() {
    return this.timeZoneTarget.value
  }

  get datasetIndex() {
    return this.datasetIdValue - 1
  }

  get isMultiAxis() {
    return this.multiAxisTarget.checked
  }
}
