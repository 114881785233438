import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Chart from 'chart.js/auto'
import { selectColor } from "./helpers/charts"

export default class extends Controller {
  static targets = [
    "filterDate",
    "chartPanel",
  ]

  connect() {
    // if turbolinks is showing preview, don't create chart yet
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.getChartData()
    }
  }

  getChartData() {
    const [startDate, endDate = startDate] = this.filterDateTarget.value.split(' to ')
    const url = this.filterDateTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ start_date: startDate, end_date: endDate }).toString(),
      contentType: "application/json",
      success: data => {
        const dataset = {}
        const dates = []
        for (const row of data) {
          dates.push(row.date)
          const dayCount = row.day_count
          for (const key in dayCount) {
            if (key in dataset) {
              dataset[key].push(dayCount[key])
            } else {
              dataset[key] = [dayCount[key]]
            }
          }
        }
        this.renderChart(dataset, dates)
      },
      error: data => {
        alert(data)
      },
    })
  }

  renderChart(dataset, labels) {
    let index = 0
    for (const key in dataset) {
      let chartId = `${key}Chart`
      // Fix "Canvas is already in use. Chart with ID '0' must be destroyed before the canvas can be reused."
      //
      // JS - Destroy existing Chart Instance to reuse <canvas> element
      let chartStatus = Chart.getChart(chartId); // <canvas> id
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      //-- End of chart destroy

      const color = selectColor(index)
      new Chart(chartId, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            backgroundColor: color,
            borderColor: color,
            data: dataset[key]
          }]
        },
        options: {
          plugins: {
            legend: {display: false}
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
              }
            }
          }
        }
      })
      index++
    }
  }
}
