import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["status"]
  static values = { path: String }

  toggleStatus() {
    const url = `${this.pathValue}/toggle_status`
    let statusContainer = this.statusTarget

    Rails.ajax({
      type: "post",
      dataType: "json",
      url: url,
      success: function (data) {
        statusContainer.innerHTML = data.html
      },
      error: function (data) {
        alert(data.error)
        location.replace(location.href)
      },
    })
  }
}
