import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "showLegend",
    "site",
    "deviceGroup",
    "deviceType",
    "device",
    "deviceTypeField",
    "startDateTime",
    "endDateTime",
    "timeZone",
    "submit",
    "chartPanel",
    "timeWindow",
    "startDate",
    "endDate",
    "startTimeWindow",
    "endTimeWindow",
  ]

  connect() {
    this.filter()
    this.allowSubmit()
  }

  clearInputs() {
    $(this.deviceSelectTarget).empty().trigger("change")
    this.deviceTarget.innerHTML = "<option value></option>"
    this.deviceTypeFieldTarget.innerHTML = "<option value></option>"
  }

  showLegend() {
    this.chart.options.plugins.legend.display = !this.chart.options.plugins.legend.display;
    this.chart.update()
  }

  siteChanged() {
    const url = this.siteTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId }).toString(),
      contentType: "application/json",
      success: data => {
        const { device_groups, device_types } = data
        this.deviceGroupTarget.innerHTML = "<option value></option>"
        device_groups.forEach((deviceGroup) => {
          const option = document.createElement("option")
          option.value = deviceGroup.id
          option.text = deviceGroup.name
          this.deviceGroupTarget.appendChild(option)
        })

        this.deviceTypeTarget.innerHTML = "<option value></option>"
        device_types.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })

        this.clearInputs()
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceGroupChanged() {
    const url = this.deviceGroupTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId, device_group_id: this.deviceGroupId }).toString(),
      contentType: "application/json",
      success: (data) => {
        this.deviceTypeTarget.innerHTML = "<option value></option>"
        data.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })
        this.clearInputs()
      },
      error: (data) => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceTypeChanged() {
    const url = this.deviceTypeTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId, device_type_id: this.deviceTypeId }).toString(),
      contentType: "application/json",
      success: data => {
        const { devices, device_type_fields } = data
        this.deviceTarget.innerHTML = "<option value></option>"
        devices.forEach(device => {
          const option = document.createElement("option")
          option.value = device.id
          option.text = device.name
          this.deviceTarget.appendChild(option)
        })

        this.deviceTypeFieldTarget.innerHTML = "<option value></option>"
        device_type_fields.forEach((field) => {
          const option = document.createElement("option")
          option.value = field
          option.text = field
          this.deviceTypeFieldTarget.appendChild(option)
        })
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceChanged() {
    this.allowSubmit()
  }

  allowSubmit() {
    this.submitTarget.disabled = !this.isValid()
  }

  filter() {
    const url = this.submitTarget.dataset.url
    const data = {
      site_id: this.siteId,
      device_id: this.deviceId,
      device_type_field: this.deviceTypeField,
      start_date_time: this.startDateTime,
      end_date_time: this.endDateTime,
      time_zone: this.timeZone,
      is_time_window: this.timeWindowTarget.checked
    }

    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams(data).toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)
        this.submitTarget.disabled = true
        this.submitTarget.textContent = "Please wait..."

        return true
      },
      success: data => this.renderDisplacement(data),
      error: data => {
        alert(data)
      },
      complete: () => {
        this.submitTarget.textContent = "Submit"
        this.submitTarget.disabled = false
        spinnerEl.remove()
      },
    })
  }

  selectColor(number) {
    const hue = number * 137.508; // use golden angle approximation
    return `hsl(${hue},100%,75%)`;
  }
  
  renderDisplacement(data) {
    const { 
      readings, 
      annotations, 
      device_type_field, 
      field_unit, 
      y_axis_title, 
      x_axis_title 
    } = data
    const ctx = document.getElementById("vertical-displacement-chart")
    ctx.height = (window.innerHeight - 350)

    const elevations = []
    const labels = []
    const datasets = []
    const chart_annotations = []
    const default_color = "#ff6961"
    const dataset = {}

    if(readings != null) {
      if(readings.length > 0){
          if ('data' in readings[0]){
              const first_datum = readings[0].data.array.sort((a, b) => (b.elevation - a.elevation))
              first_datum.forEach(datum => {
                elevations.push(datum.elevation)
                dataset[datum.elevation] = {}
                dataset[datum.elevation]['data'] = []
              })
          }
      }

      readings.forEach((reading, idx) => {
        const timestamp = moment(reading.time_in_zone, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
        labels.push(timestamp)

        elevations.forEach((elevation, e_idx) => {
            const color = this.selectColor(e_idx)
            dataset[elevation]['label'] = elevation
            dataset[elevation]['fill'] = false
            dataset[elevation]['backgroundColor'] = color
            dataset[elevation]['borderColor'] = color

            reading.data.array.forEach((datum) => {
                if(datum["elevation"] == elevation) {
                    dataset[elevation]['data'].push(datum[this.deviceTypeField])
                }
            })
        })
      })

      elevations.forEach((elevation) => {
        datasets.push(dataset[elevation])
      })

      let label = device_type_field
      if (field_unit != "") {
        label = label.concat(" \(", field_unit, "\)")
      }

      annotations.map((annotation, index) => {
        chart_annotations.push(
          {
            type: 'line',
            id: 'alert'+ index + '_min',
            value: annotation.alert_min,
            endValue: annotation.alert_min,
            borderColor: annotation.color || default_color,
            borderWidth: 1,
            display: (annotation.alert_min) ? true : false,
            label: {
              enabled: true,
              position: index == 0 ? "start" : "end",
              content: `${annotation.title || ""} ${annotation.alert_min}`,
              backgroundColor: 'rgba(0,0,0,0.3)'
            },
            scaleID: index == 0 ? "y" : "y1"
          },
          {
            type: 'line',
            id: 'alert'+ index + '_max',
            value: annotation.alert_max,
            endValue: annotation.alert_max,
            borderColor: annotation.color || default_color,
            borderWidth: 1,
            display: (annotation.alert_max) ? true : false,
            label: {
              enabled: true,
              position: index == 0 ? "start" : "end",
              content: `${annotation.title || ""} ${annotation.alert_max}`,
              backgroundColor: 'rgba(0,0,0,0.3)'
            },
            scaleID: index == 0 ? "y" : "y1"
          })
      });
      
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          plugins: {
            annotation: {
              drawTime: "afterDraw",
              annotations: chart_annotations
            }
          },
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0,
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: y_axis_title
              }
            },
            x: {
              title: {
                display: true,
                text: x_axis_title
              },
              ticks: {
                  stepSize: 10
              }
            },
          },
        },
      })

      // reinitialize on chart creation
      this.showLegendTarget.checked = true
      this.showLegendTarget.disabled = false
    }
  }

  isValid() {
    if (this.deviceTypeId.length == 0) {
      return false
    }
    if (this.deviceId.length == 0) {
      return false
    }
    if (this.deviceTypeField.length == 0) {
      return false
    }
    if (this.startDateTime.length == 0) {
      return false
    }
    if (this.endDateTime.length == 0) {
      return false
    }

    return true
  }

  redrawCanvas() {
    document.getElementById("vertical-displacement-chart")?.remove()
    const canvas = document.createElement("canvas")
    canvas.id = "vertical-displacement-chart"
    canvas.height = (window.innerHeight - 350)

    document.querySelector(".chart-area").appendChild(canvas)
  }

  formatTimeWindow(date, time) {
    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get siteId() {
    return this.siteTarget.value
  }

  get deviceGroupId() {
    return this.deviceGroupTarget.value
  }

  get deviceTypeId() {
    return this.deviceTypeTarget.value
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTarget.value
  }

  get startDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.startDateTarget.value, this.startTimeWindowTarget.value)
    }
    return this.startDateTimeTarget.value
  }

  get endDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.endDateTarget.value, this.endTimeWindowTarget.value)
    }
    return this.endDateTimeTarget.value
  }

  get timeZone() {
    return this.timeZoneTarget.value
  }
}
