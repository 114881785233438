import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addDevice", "device"]

  connect() {
    this.addDeviceTarget.disabled = true
  }

  deviceChanged() {
    this.addDeviceTarget.disabled = this.deviceTarget.value === ""
  }
}
