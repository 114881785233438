;(function ($) {
  "use strict" // Start of use strict

  $(document).on("turbolinks:load", function () {
    var data = $("input.file-input").data()
    var options = $.extend(
      {
        theme: "fa",
        mainClass: "input-group-sm",
        uploadUrl: "",
        uploadAsync: true,
      },
      data
    )

    $("input.file-input").fileinput(options)
  })

  $(document).on("turbolinks:before-cache", function () {
    $("input.file-input").fileinput("destroy")
  })
})(jQuery) // End of use strict
