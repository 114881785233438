import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { 
  selectColor,
  getAnnotations,
  annotationMouseEvents,
  mergeAnnotations,
  mergeLabels
} from "../helpers/charts"

export default class extends Controller {
  static targets = [
    "showLegend",
    "showAnnotations",
    "device",
    "deviceGroup",
    "deviceType",
    "deviceTypeField",
    "chartPanel",
  ]

  static values = {
    url: String
  }

  connect() {
    if (this.deviceId) {
      this.filter()
    } else {
      // group histogram
      // populate select with device type field options
      this.getDeviceTypeField()
    }
  }

  showLegend() {
    this.chart.options.plugins.legend.display = !this.chart.options.plugins.legend.display
    this.chart.update()
  }

  showAnnotations() {
    const annotations = this.chart.options.plugins.annotation.annotations
    annotations.forEach(annotation => annotation.display = !annotation.display)
    this.chart.update()
  }

  getDeviceTypeField() {
    const url = this.deviceTypeFieldTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ device_group_id: this.deviceGroupId, device_type_id: this.deviceTypeId }).toString(),
      contentType: "application/json",
      success: data => {
        const { device_type_fields, default_field } = data

        device_type_fields.forEach(field => {
          const option = document.createElement("option")
          option.value = field
          option.text = field
          if (field === default_field) option.selected = true
          this.deviceTypeFieldTarget.appendChild(option)
        })

        this.filter()
      },
      error: data => {
        alert(data)
      },
    })
  }


  filter() {
    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    let url = this.urlValue
    let data = {
      device_id: this.deviceId,
      device_group_id: this.deviceGroupId,
      device_type_id: this.deviceTypeId,
      device_type_field: this.deviceTypeField
    }
    // set to currently selected device type field
    $("#device_type_field").val(this.deviceTypeField)

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams(data).toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)

        return true
      },
      success: data => {
        if (data.readings) {
          this.renderHistogram(data)
        }
      },
      error: data => {
        alert(data)
      },
      complete: () => {
        spinnerEl.remove()
      },
    })
  }

  renderHistogram(data) {
    const ctx = document.getElementById("histogram-chart")

    const allAnnotations = []
    const datasets = []
    const labels = []
    const y_title = []
    let x_title, y_reverse

    const { 
      devices,
      readings, 
      alert_annotations, 
      annotations, 
      device_type_field, 
      field_unit, 
      y_axis_title, 
      x_axis_title,
      y_axis_reverse = false,
      precision
    } = data
    x_title = x_axis_title
    y_title.push(y_axis_title)
    y_reverse = y_axis_reverse

    readings.forEach((reading, index) => {
      if (reading.length) {
        const values = []
        const color = selectColor(index)
        
        let label = devices[index][0]   // get device name
        if (field_unit && field_unit.length) label = `${label} (${field_unit})`

        reading.forEach(({ time_in_zone, data }) => {
          if(!(device_type_field in data)){
            return
          }
          if(isNaN(data[device_type_field])){
            return
          }

          const timestamp = moment(time_in_zone, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
          labels.push(timestamp)
          values.push(
            {
              x: timestamp,
              y: parseFloat(data[device_type_field].toFixed(precision))
            }
          )
        })
      
        datasets.push(
          {
            label: label,
            data: values,
            backgroundColor: color,
            borderColor: color,
            yAxisID: "y"
          }
        )
      }

      // get alert_annotations config for chart 
      if (alert_annotations && alert_annotations.length) {
        const { datasetAnnotations } = getAnnotations({ annotations: alert_annotations })
        allAnnotations.push(...datasetAnnotations)
      }

      // get annotations config for chart
      if (annotations && annotations.length) {
        const { datasetAnnotations, annotationTimestamps } = getAnnotations({ annotations })
        if (annotationTimestamps.length) labels.push(...annotationTimestamps)
        allAnnotations.push(...datasetAnnotations)
      }
    })
    
    // merge same labels and annotations
    const mergedLabels = mergeLabels(labels)
    const mergedAnnotations = mergeAnnotations(allAnnotations)
    
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: mergedLabels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        spanGaps: true,
        plugins: {
          annotation: {
            annotations: mergedAnnotations,
            ...annotationMouseEvents
          },
          tooltip: {
            callbacks: {
              label: (ctx) => (`${ctx.dataset.label}: ${ctx.raw.y}`)
            }
          }
        },
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: x_title
            }
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            title: {
              display: true,
              text: y_title[0]
            },
            reverse: y_reverse
          }
        },
      },
    })

    // reinitialize on chart creation
    this.showLegendTarget.checked = true
    this.showLegendTarget.disabled = false
    this.showAnnotationsTarget.checked = mergedAnnotations.length
    this.showAnnotationsTarget.disabled = !mergedAnnotations.length
  }

  redrawCanvas() {
    document.getElementById("histogram-chart")?.remove()
    const canvas = document.createElement("canvas")
    canvas.id = "histogram-chart"

    document.querySelector(".histogram-chart-area").appendChild(canvas)
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceGroupId() {
    return this.deviceGroupTarget.value
  }

  get deviceTypeId() {
    return this.deviceTypeTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTarget.value
  }
}
