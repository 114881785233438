import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["cardType", "site", "device", "field", "submit"]

  connect() {
    // toggle submit button enability
    this.allowSubmit()
  }

  cardTypeChanged() {
    this.submitTarget.disabled = true
    const url = this.cardTypeTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ card_type: this.cardType }).toString(),
      contentType: "application/json",
      success: data => {
        this.siteTarget.innerHTML = "<option value></option>"
        this.deviceTarget.innerHTML = "<option value></option>"
        this.fieldTarget.innerHTML = "<option value></option>"
        
        data.forEach(site => {
          const option = document.createElement("option")
          option.value = site.id
          option.text = site.name
          this.siteTarget.appendChild(option)
        })
      },
      error: data => {
        alert(data.error)
      },
    })
  }

  siteChanged() {
    this.submitTarget.disabled = true
    const url = this.siteTarget.dataset.url
    const siteId = this.siteTarget.value

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: siteId, card_type: this.cardType }).toString(),
      contentType: "application/json",
      success: data => {
        this.deviceTarget.innerHTML = "<option value></option>"
        this.fieldTarget.innerHTML = "<option value></option>"

        data.forEach(device => {
          const option = document.createElement("option")
          option.value = device.id
          option.text = device.name
          this.deviceTarget.appendChild(option)
        })
      },
      error: data => {
        alert(data.error)
      },
    })
  }

  deviceChanged() {
    this.submitTarget.disabled = true
    const url = this.deviceTarget.dataset.url
    const deviceId = this.deviceTarget.value

    if (!deviceId) return

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      contentType: "application/json",
      data: new URLSearchParams({ device_id: deviceId }).toString(),
      success: data => {
        this.fieldTarget.innerHTML = "<option value></option>"

        data.forEach(field => {
          const option = document.createElement("option")
          option.value = field
          option.text = field
          this.fieldTarget.appendChild(option)
        })
      },
      error: data => {
        alert(data.error)
      },
    })
  }

  allowSubmit() {
    this.submitTarget.disabled = !this.isValid()
  }

  isValid() {
    if (this.cardType &&
        this.siteTarget.value &&
        this.deviceTarget.value &&
        this.fieldTarget.value) {
      return true
    }
    return false
  }

  get cardType() {
    return this.cardTypeTarget.value
  }
}
