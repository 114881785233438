import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { Chart } from "chart.js"
import {
  selectColorDisplacement,
  getAnnotations,
  annotationMouseEvents,
  mergeAnnotations,
  mergeLabels,
} from "./helpers/charts"

export default class extends Controller {
  static targets = [
    "showLegend",
    "showAnnotations",
    "relevantLegends",
    "site",
    "deviceGroup",
    "deviceType",
    "device",
    "deviceSelect",
    "deviceTypeField",
    "startDateTime",
    "endDateTime",
    "timeZone",
    "submit",
    "chartPanel",
    "timeWindow",
    "startDate",
    "endDate",
    "startTimeWindow",
    "endTimeWindow",
  ]

  static values = {
    selectedDevices: Array,
  }

  connect() {
    this.selectedDevicesValue = $(this.deviceSelectTarget).val()
    $(this.deviceSelectTarget).on("select2:select", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    $(this.deviceSelectTarget).on("select2:unselect", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    // if turbolinks is showing preview, don't create chart yet
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.allowSubmit()
      this.filter()
    }
  }

  showLegend() {
    for (let i = 0; i < this.selectedDevicesValue.length; i++) {
      const chart = Chart.getChart("displacement-chart-" + i)
      chart.options.plugins.legend.display = !chart.options.plugins.legend.display
      chart.update()
    }
  }

  showAnnotations() {
    for (let i = 0; i < this.selectedDevicesValue.length; i++) {
      const chart = Chart.getChart("displacement-chart-" + i)
      const annotations = chart.options.plugins.annotation.annotations
      annotations.forEach(annotation => annotation.display = !annotation.display)
      chart.update()
    }
  }

  relevantLegends() {
    const generateLabelsLegendHandler = Chart.defaults.plugins.legend.labels.generateLabels
    for (let i = 0; i < this.selectedDevicesValue.length; i++) {
      const chart = Chart.getChart("displacement-chart-" + i)
      if (this.relevantLegendsTarget.checked) {
        // reset the hidden attribute of the "other" legends before grouping them to "Others"
        const labels = generateLabelsLegendHandler(chart)
        const others = labels.filter(el => el.fillStyle === "#d3d3d3")
        others.forEach(item => chart.getDatasetMeta(item.datasetIndex).hidden = false)
      }
      chart.update()
    }
  }

  getAllDevices() {
    const allDevices = [...$(this.deviceSelectTarget).children()].map(o => o.value)
    allDevices.shift()
    return allDevices
  }

  clearSelection() {
    $(this.deviceSelectTarget).val(null).trigger("change")
    this.selectedDevicesValue = []
    this.allowSubmit()
  }

  clearInputs() {
    $(this.deviceSelectTarget).empty().trigger("change")
    this.deviceTypeFieldTarget.innerHTML = "<option value></option>"
  }

  siteChanged() {
    const url = this.siteTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId }).toString(),
      contentType: "application/json",
      success: (data) => {
        const { device_groups, device_types } = data
        this.deviceGroupTarget.innerHTML = "<option value></option>"
        device_groups.forEach((deviceGroup) => {
          const option = document.createElement("option")
          option.value = deviceGroup.id
          option.text = deviceGroup.name
          this.deviceGroupTarget.appendChild(option)
        })

        this.deviceTypeTarget.innerHTML = "<option value></option>"
        device_types.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })

        this.clearInputs()
      },
      error: (data) => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceGroupChanged() {
    const url = this.deviceGroupTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ device_group_id: this.deviceGroupId }).toString(),
      contentType: "application/json",
      success: (data) => {
        this.deviceTypeTarget.innerHTML = "<option value></option>"
        data.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })
        this.clearInputs()
      },
      error: (data) => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceTypeChanged() {
    const url = this.deviceTypeTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ device_group_id: this.deviceGroupId, device_type_id: this.deviceTypeId }).toString(),
      contentType: "application/json",
      success: (data) => {
        const { devices, device_type_fields } = data
        $(this.deviceSelectTarget).empty().trigger("change")
        this.deviceSelectTarget.innerHTML = "<option value='0'>--Select All--</option>"
        devices.forEach((device) => {
          const option = document.createElement("option")
          option.value = device.id
          option.text = device.name
          this.deviceSelectTarget.appendChild(option)
        })

        this.deviceTypeFieldTarget.innerHTML = "<option value></option>"
        device_type_fields.forEach((field) => {
          const option = document.createElement("option")
          option.value = field
          option.text = field
          this.deviceTypeFieldTarget.appendChild(option)
        })
      },
      error: (data) => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceChanged(e) {
    if (e.detail) {
      let selected = e.detail.devices

      // select all devices
      if (selected[0] === "0") {
        selected = this.getAllDevices()
        $(this.deviceSelectTarget).val(selected).trigger("change")
      }

      this.selectedDevicesValue = selected
    }
    this.allowSubmit()
  }

  allowSubmit() {
    this.submitTarget.disabled = !this.isValid()
  }

  submit() {
    this.filter()
  }

  filter() {
    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    const url = this.submitTarget.dataset.url
    const data = {
      site_id: this.siteId,
      device_group_id: this.deviceGroupId,
      device_type_id: this.deviceTypeId,
      device_type_field: this.deviceTypeField,
      start_date_time: this.startDateTime,
      end_date_time: this.endDateTime,
      time_zone: this.timeZone,
      is_time_window: this.timeWindowTarget.checked
    }
    // add device ids to search parameters
    const searchParams = new URLSearchParams(data)
    this.selectedDevicesValue.forEach((id) => searchParams.append("device_id[]", id))

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: searchParams.toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)
        this.submitTarget.disabled = true
        this.submitTarget.textContent = "Please wait..."

        return true
      },
      success: (data) => {
        if (data.readings) {
          this.renderDisplacement(data)
        }
      },
      error: (data) => {
        alert(data)
      },
      complete: () => {
        this.submitTarget.textContent = "Submit"
        this.submitTarget.disabled = false
        spinnerEl.remove()
      },
    })
  }

  renderDisplacement(data) {
    let showAnnotations = false
    const {
      readings,
      devices,
      alert_annotations,
      annotations,
      y_axis_title,
      x_axis_title
    } = data

    readings.forEach((readings, index) => {
      const ctx = $("#displacement-chart-" + index)

      const allAnnotations = []
      const labels = []
      const datasets = []

      if (readings.length && "data" in readings[0]) {
        const first_datum = readings[0].data.array.sort((a, b) => b.elevation - a.elevation)
        first_datum.forEach((datum) => labels.push(`${datum.elevation}`))

        readings.forEach((reading) => {
          const timestamp = moment(reading.time_in_zone, "YYYY-MM-DD hh:mm A").format("DD-MMM-YYYY hh:mm A")
          const dataArray = reading.data.array.sort((a, b) => b.elevation - a.elevation)
          const dataset = {
            label: timestamp,
            fill: false,
            data: [],
          }

          dataArray.forEach((datum, index) => {
            if (datum[this.deviceTypeField] != null) {
              dataset.data.push({
                x: datum[this.deviceTypeField],
                y: `${datum.elevation}`,
              })
            }
          })

          if (dataset.data.length) datasets.push(dataset)
        })
      }

      datasets.forEach((dataset, idx) => {
        const color = selectColorDisplacement(idx, datasets.length)
        dataset["backgroundColor"] = color
        dataset["borderColor"] = color
      })

      // get alert_annotations config for chart
      if (alert_annotations && alert_annotations.length) {
        const { datasetAnnotations } = getAnnotations({ annotations: alert_annotations[index] })
        allAnnotations.push(...datasetAnnotations)
      }

      // get annotations config for chart
      if (annotations && annotations.length) {
        const { datasetAnnotations, displacementLabels } = getAnnotations({ annotations: annotations[index] })
        if (displacementLabels && displacementLabels.length) labels.push(...displacementLabels)
        allAnnotations.push(...datasetAnnotations)
      }

      // merge same labels and annotations
      const mergedAnnotations = mergeAnnotations(allAnnotations)
      const mergedLabels = mergeLabels(labels, 1)

      // sort dataset from latest to oldest
      datasets.reverse()

      const generateLabelsLegendHandler = Chart.defaults.plugins.legend.labels.generateLabels
      const legendClickHandler = Chart.defaults.plugins.legend.onClick
      let others = []

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: mergedLabels,
          datasets: datasets,
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: devices[index][0],
              fullSize: false
            },
            annotation: {
              annotations: mergedAnnotations,
              ...annotationMouseEvents
            },
            legend: {
              reverse: true,
              labels: {
                generateLabels: (chart) => {
                  const labels = generateLabelsLegendHandler(chart)
                  if (this.relevantLegendsTarget.checked) {
                    const relevant = labels.filter(el => el.fillStyle !== "#d3d3d3")
                    others = labels.filter(el => el.fillStyle === "#d3d3d3")
                    if (others.length) {
                      relevant.unshift({
                        text: "Others",
                        hidden: others[0].hidden,
                        fillStyle: "#d3d3d3",
                        strokeStyle: "#d3d3d3"
                      })
                    }
                    return relevant
                  }

                  return labels
                }
              },
              onClick: (e, legendItem, legend) => {
                if (legendItem.text === "Others") {
                  const ci = legend.chart
                  others.forEach(item => ci.getDatasetMeta(item.datasetIndex).hidden = !legendItem.hidden)
                  ci.update()
                  return
                }
                legendClickHandler(e, legendItem, legend);
              }
            }
          },
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0,
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: y_axis_title
              }
            },
            x: {
              title: {
                display: true,
                text: x_axis_title
              },
              ticks: {
                stepSize: 10
              }
            },
          },
        },
      })

      if (mergedAnnotations.length) showAnnotations = showAnnotations || mergedAnnotations.length
    })

    // reinitialize on chart creation
    this.showLegendTarget.checked = true
    this.showLegendTarget.disabled = false
    this.showAnnotationsTarget.checked = showAnnotations
    this.showAnnotationsTarget.disabled = !showAnnotations
    this.relevantLegendsTarget.checked = true
    this.relevantLegendsTarget.disabled = false
  }

  isValid() {
    if (
      this.deviceTypeId.length == 0 ||
      this.selectedDevicesValue.length == 0 ||
      this.deviceTypeField.length == 0 ||
      this.startDateTime.length == 0 ||
      this.endDateTime.length == 0
    ) {
      return false
    }

    return true
  }

  redrawCanvas() {
    const div = document.createElement("div")

    // remove all previous canvas
    $(".chart-container").empty()

    if (this.selectedDevicesValue.length === 1) {
      div.classList.add("col", "p-4")
      const canvas = document.createElement("canvas")
      canvas.id = "displacement-chart-0"
      $(".chart-container").append(canvas)
    } else {
      const height = $(".chart-container").height()
      div.style.height = `${height * 0.9}px`
      div.classList.add("col-lg-6", "px-4", "py-3")

      for (let i = 0; i < this.selectedDevicesValue.length; i++) {
        const canvas = document.createElement("canvas")
        canvas.id = "displacement-chart-" + i
        $(".chart-container").append(canvas)
      }
    }
    $("canvas").wrap(div)
  }

  formatTimeWindow(date, time) {
    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get siteId() {
    return this.siteTarget.value
  }

  get deviceGroupId() {
    return this.deviceGroupTarget.value
  }

  get deviceTypeId() {
    return this.deviceTypeTarget.value
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTarget.value
  }

  get startDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.startDateTarget.value, this.startTimeWindowTarget.value)
    }
    return this.startDateTimeTarget.value
  }

  get endDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.endDateTarget.value, this.endTimeWindowTarget.value)
    }
    return this.endDateTimeTarget.value
  }

  get timeZone() {
    return this.timeZoneTarget.value
  }

  get datasetIndex() {
    return this.datasetIdValue - 1
  }
}
