import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "site",
    "deviceGroup",
    "deviceType",
    "deviceSelect",
    "startDateTime",
    "endDateTime",
    "timeZone",
    "submit",
    "timeWindow",
    "startDate",
    "endDate",
    "startTimeWindow",
    "endTimeWindow",
  ]

  connect() {
    this.selectedDevicesValue = $(this.deviceSelectTarget).val()
    $(this.deviceSelectTarget).on("select2:select", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    $(this.deviceSelectTarget).on("select2:unselect", function (e) {
      const event = new CustomEvent("change", { bubbles: true, detail: { devices: $(this).val() } })
      this.dispatchEvent(event)
    })

    // if turbolinks is showing preview, don't create chart yet
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.allowSubmit()
    }
  }

  clearInputs() {
    $(this.deviceSelectTarget).empty().trigger("change")
    this.deviceSelectTarget.innerHTML = "<option value></option>"
  }

  clearSelection() {
    $(this.deviceSelectTarget).val(null).trigger('change');
    this.selectedDevicesValue = []
    this.allowSubmit()
  }

  getAllDevices() {
    const allDevices = [...$(this.deviceSelectTarget).children()].map(o => o.value)
    allDevices.shift()
    return allDevices
  }

  siteChanged() {
    const url = this.siteTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId }).toString(),
      contentType: "application/json",
      success: data => {
        const { device_groups, device_types } = data
        this.deviceGroupTarget.innerHTML = "<option value></option>"
        device_groups.forEach((deviceGroup) => {
          const option = document.createElement("option")
          option.value = deviceGroup.id
          option.text = deviceGroup.name
          this.deviceGroupTarget.appendChild(option)
        })

        this.deviceTypeTarget.innerHTML = "<option value></option>"
        device_types.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })

        this.clearInputs()
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceGroupChanged() {
    const url = this.deviceGroupTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId, device_group_id: this.deviceGroupId }).toString(),
      contentType: "application/json",
      success: (data) => {
        this.deviceTypeTarget.innerHTML = "<option value></option>"
        data.forEach((deviceType) => {
          const option = document.createElement("option")
          option.value = deviceType.id
          option.text = deviceType.name
          this.deviceTypeTarget.appendChild(option)
        })
        this.clearInputs()
      },
      error: (data) => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceTypeChanged() {
    const url = this.deviceTypeTarget.dataset.url

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams({ site_id: this.siteId, device_group_id: this.deviceGroupId, device_type_id: this.deviceTypeId }).toString(),
      contentType: "application/json",
      success: data => {
        $(this.deviceSelectTarget).empty().trigger('change');
        this.deviceSelectTarget.innerHTML = "<option value='0'>--Select All--</option>"
        data.forEach(device => {
          const option = document.createElement("option")
          option.value = device.id
          option.text = device.name
          this.deviceSelectTarget.appendChild(option)
        })
      },
      error: data => {
        alert(data)
      },
    })

    this.submitTarget.disabled = true
  }

  deviceChanged(e) {
    if (e.detail) {
      let selected = e.detail.devices

      // select all devices
      if(selected[0] === '0') {
        selected = this.getAllDevices()
        $(this.deviceSelectTarget).val(selected).trigger('change');
      }

      this.selectedDevicesValue = selected
    }
    this.allowSubmit()
  }

  allowSubmit() {
    this.submitTarget.disabled = !this.isValid()
  }

  filter() {
    const url = this.submitTarget.dataset.url
    const data = {
      site_id: this.siteId,
      device_group_id: this.deviceGroupId,
      device_type_id: this.deviceTypeId,
      start_date_time: this.startDateTime,
      end_date_time: this.endDateTime,
      time_zone: this.timeZone,
      "time-window": this.timeWindowTarget.checked ? "" : false
    }

    // add device ids to search parameters
    let searchParams = new URLSearchParams(data)
    this.selectedDevicesValue.forEach(id => searchParams.append('devices[]', id))

    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams(data).toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)
        this.submitTarget.disabled = true
        this.submitTarget.textContent = "Please wait..."

        return true
      },
      success: data => this.renderData(data),
      error: data => {
        alert(data.error)
      },
      complete: () => {
        this.submitTarget.textContent = "Submit"
        this.submitTarget.disabled = false
        spinnerEl.remove()
      },
    })
  }

  isValid() {
    if (this.selectedDevicesValue.length == 0) {
      return false
    }

    return true
  }

  redrawCanvas() {
    document.getElementById("data-table")?.remove()
    const canvas = document.createElement("canvas")
    canvas.id = "data-table"

    document.querySelector(".table-area").appendChild(canvas)
  }

  formatTimeWindow(date, time) {
    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get siteId() {
    return this.siteTarget.value
  }

  get deviceGroupId() {
    return this.deviceGroupTarget.value
  }

  get deviceTypeId() {
    return this.deviceTypeTarget.value
  }

  get startDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.startDateTarget.value, this.startTimeWindowTarget.value)
    }
    return this.startDateTimeTarget.value
  }

  get endDateTime() {
    if (this.timeWindowTarget.checked) {
      return this.formatTimeWindow(this.endDateTarget.value, this.endTimeWindowTarget.value)
    }
    return this.endDateTimeTarget.value
  }

  get timeZone() {
    return this.timeZoneTarget.value
  }
}
