import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = ["notifications"]

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "NotificationsCenterChannel",
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    )
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const element = this.notificationsTarget
    element.innerHTML = data.html
  }
}
