import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    "notifications",
    "selectAll",
    "checkbox",
    "selectedCount",
    "notificationsButton",
    "clearSelection",
  ]

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "NotificationsChannel",
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    )

    this.refreshCount()
  }

  selectAll(event) {
    let checked = event.target.checked

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })

    this.refreshCount()
  }

  unselectAll(event) {
    event.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })

    this.refreshCount()
  }

  refreshCount() {
    const checkedCheckboxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    )
    const count = checkedCheckboxes.length
    let newHtmlContent = `No notifications on this page are selected.`

    if (count == 1) {
      newHtmlContent = `<strong>1</strong> notification on this page is selected.`
    } else if (count > 1) {
      newHtmlContent = `<strong>${count}</strong> notifications on this page are selected.`
    } else {
      this.selectAllTarget.checked = false
    }

    this.selectedCountTarget.innerHTML = newHtmlContent

    if (count > 0) {
      this.notificationsButtonTargets.forEach(
        (button) => (button.disabled = false)
      )
      this.clearSelectionTarget.classList.remove("d-none")
    } else {
      this.notificationsButtonTargets.forEach(
        (button) => (button.disabled = true)
      )
      this.clearSelectionTarget.classList.add("d-none")
    }
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const notificationList = this.notificationsTarget
    let oldNotification = notificationList.querySelector(
      `#notification_${data.id}`
    )

    if (oldNotification !== null) {
      oldNotification.outerHTML = data.html
    } else {
      notificationList.insertAdjacentHTML("afterbegin", data.html)
    }
  }
}
