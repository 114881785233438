/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("turbolinks").start()

require("@rails/activestorage").start()
require("channels")

require("@fortawesome/fontawesome-free/js/all")
require("jquery.easing")

import Chart from "chart.js/auto"
import annotationPlugin from "chartjs-plugin-annotation"
Chart.register(annotationPlugin)
global.Chart = Chart

global.moment = require("moment")

global.toastr = require("toastr")
toastr.options.closeButton = true

require("./plugins/sb-admin-2")
require("./plugins/fileinput")
require("./plugins/toastr_flash")
require("./plugins/init_flatpickr")
require("./plugins/select2")
require("./plugins/oms")

import "jquery"
import "bootstrap"
import "bootstrap-fileinput"
import "bootstrap-fileinput/themes/fa/theme"
import "controllers" // Import all Stimulus controllers

import "./src/application"

FontAwesomeConfig.mutateApproach = "sync"

require("./custom/sidebar")
require("./custom/flatpickr-inline")
import "./custom/dashboard_card"
import "./custom/image_uploader"
import "./custom/upload_documents"
import "./custom/delete_reading_modal"
import "./custom/sortable"

import "leaflet/dist/leaflet.css"
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css" // Re-uses images from ~leaflet package
import * as L from "leaflet"
import "leaflet-defaulticon-compatibility"

require("leaflet")
require("jquery-ui-dist/jquery-ui")

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  })
})

// register highlight.js and the JSON language
import hljs from "highlight.js/lib/core"
import json from "highlight.js/lib/languages/json"
hljs.registerLanguage("json", json)

 document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("code pre").forEach(el => hljs.highlightBlock(el))
})
