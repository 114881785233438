import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import {
  selectColorDisplacement,
  getAnnotations,
  annotationMouseEvents,
  mergeAnnotations,
  mergeLabels
} from "../helpers/charts"

export default class extends Controller {
  static targets = [
    "showLegend",
    "showAnnotations",
    "relevantLegends",
    "device",
    "deviceTypeField",
    "deviceGroup",
    "deviceType",
    "chartPanel",
  ]

  static values = {
    url: String
  }

  connect() {
    this.filter()
  }

  showLegend() {
    this.chart.options.plugins.legend.display = !this.chart.options.plugins.legend.display
    this.chart.update()
  }

  showAnnotations() {
    const annotations = this.chart.options.plugins.annotation.annotations
    annotations.forEach(annotation => annotation.display = !annotation.display)
    this.chart.update()
  }

  relevantLegends() {
    const generateLabelsLegendHandler = Chart.defaults.plugins.legend.labels.generateLabels
    if (this.relevantLegendsTarget.checked) {
      // reset the hidden attribute of the "other" legends before grouping them to "Others"
      const labels = generateLabelsLegendHandler(this.chart)
      const others = labels.filter(el => el.fillStyle === "#d3d3d3")
      others.forEach(item => this.chart.getDatasetMeta(item.datasetIndex).hidden = false)
    }
    this.chart.update()
  }

  filter() {
    const url = this.urlValue
    const data = {
      device_id: this.deviceId,
      device_type_field: this.deviceTypeField,
      device_group_id: this.deviceGroupId,
      device_type_id: this.deviceTypeId,
    }

    const spinnerEl = document.createElement("div")
    spinnerEl.id = "spinner"
    spinnerEl.innerHTML = this.spinnerHTML

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "json",
      data: new URLSearchParams(data).toString(),
      contentType: "application/json",
      beforeSend: () => {
        this.redrawCanvas()
        this.chartPanelTarget.appendChild(spinnerEl)

        return true
      },
      success: data => {
        if (data.readings) {
          this.renderDisplacement(data)
        }
      },
      error: data => {
        alert(data)
      },
      complete: () => {
        spinnerEl.remove()
      }
    })
  }

  renderDisplacement(data) {
    const ctx = $("#displacement-chart")
    const allAnnotations = []
    const labels = []
    const datasets = []

    const {
      readings: allReadings,
      alert_annotations,
      annotations,
      y_axis_title,
      x_axis_title
    } = data
    const readings = allReadings[0]


    if (readings.length && ('data' in readings[0])) {
      const first_datum = readings[0].data.array.sort((a, b) => (b.elevation - a.elevation))
      first_datum.forEach(datum => labels.push(`${datum.elevation}`))


      readings.forEach(reading => {
        const timestamp = moment(reading.time_in_zone, 'YYYY-MM-DD hh:mm A').format('DD-MMM-YYYY hh:mm A')
        const dataArray = reading.data.array.sort((a, b) => (b.elevation - a.elevation))
        const dataset = {
          label: timestamp,
          fill: false,
          data: []
        }

        dataArray.forEach((datum, index) => {
          if (datum[this.deviceTypeField] != null) {
            dataset.data.push(
              {
                x: datum[this.deviceTypeField],
                y: `${datum.elevation}`,
              }
            )
          }
        })

        if (dataset.data.length) datasets.push(dataset)
      })
    }

    datasets.forEach((dataset, idx) => {
      const color = selectColorDisplacement(idx, datasets.length)
      dataset['backgroundColor'] = color
      dataset['borderColor'] = color
    })

    // get alert_annotations config for chart
    if (alert_annotations && alert_annotations.length) {
      const { datasetAnnotations } = getAnnotations({ annotations: alert_annotations[0] })
      allAnnotations.push(...datasetAnnotations)
    }

    // get annotations config for chart
    if (annotations && annotations.length) {
      const { datasetAnnotations, displacementLabels } = getAnnotations({ annotations: annotations[0] })
      if (displacementLabels && displacementLabels.length) labels.push(...displacementLabels)
      allAnnotations.push(...datasetAnnotations)
    }

    // merge same labels and annotations
    const mergedAnnotations = mergeAnnotations(allAnnotations)
    const mergedLabels = mergeLabels(labels, 1)

    // sort dataset from latest to oldest
    datasets.reverse()

    const generateLabelsLegendHandler = Chart.defaults.plugins.legend.labels.generateLabels
    const legendClickHandler = Chart.defaults.plugins.legend.onClick
    let others = []

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: mergedLabels,
        datasets: datasets,
      },
      options: {
        plugins: {
          annotation: {
            annotations: mergedAnnotations,
            ...annotationMouseEvents
          },
          legend: {
            reverse: true,
            labels: {
              generateLabels: (chart) => {
                const labels = generateLabelsLegendHandler(chart)
                if (this.relevantLegendsTarget.checked) {
                  const relevant = labels.filter(el => el.fillStyle !== "#d3d3d3")
                  others = labels.filter(el => el.fillStyle === "#d3d3d3")
                  if (others.length) {
                    relevant.unshift({
                      text: "Others",
                      hidden: others[0].hidden,
                      fillStyle: "#d3d3d3",
                      strokeStyle: "#d3d3d3"
                    })
                  }
                  return relevant
                }

                return labels
              }
            },
            onClick: (e, legendItem, legend) => {
              if (legendItem.text === "Others") {
                const ci = legend.chart
                others.forEach(item => ci.getDatasetMeta(item.datasetIndex).hidden = !legendItem.hidden)
                ci.update()
                return
              }
              legendClickHandler(e, legendItem, legend);
            }
          }
        },
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: y_axis_title
            }
          },
          x: {
            title: {
              display: true,
              text: x_axis_title
            },
            ticks: {
              stepSize: 10
            }
          },
        },
      },
    })

    // reinitialize on chart creation
    this.showLegendTarget.checked = true
    this.showLegendTarget.disabled = false
    this.showAnnotationsTarget.checked = mergedAnnotations.length
    this.showAnnotationsTarget.disabled = !mergedAnnotations.length
    this.relevantLegendsTarget.checked = true
    this.relevantLegendsTarget.disabled = false
  }

  redrawCanvas() {
    $("#displacement-chart")?.remove()
    const canvas = document.createElement("canvas")
    canvas.id = "displacement-chart"

    $(".displacement-chart-area").append(canvas)
  }

  get spinnerHTML() {
    return `
    <div class="text-gray">Fetching data...</div>
    <div class="spinner-box">
      <div class="solar-system">
          <div class="earth-orbit orbit">
            <div class="planet earth"></div>
            <div class="venus-orbit orbit">
                <div class="planet venus"></div>
                <div class="mercury-orbit orbit">
                  <div class="planet mercury"></div>
                  <div class="sun"></div>
                </div>
            </div>
          </div>
      </div>
    </div>`
  }

  get deviceId() {
    return this.deviceTarget.value
  }

  get deviceTypeField() {
    return this.deviceTypeFieldTarget.value
  }

  get deviceGroupId() {
    return this.deviceGroupTarget.value
  }

  get deviceTypeId() {
    return this.deviceTypeTarget.value
  }
}
